import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NbMenuBag, NbMenuService, NbSidebarService } from "@nebular/theme";
import { filter } from "rxjs/operators";
import { AuthenticationService } from "../../../core/services/authentication.service";
import { LayoutService } from "../../../core/services/layout.service";
import { LoaderService } from "../../../core/services/loader.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userMenu = [
    { title: "Perfil", icon: "person-outline" },
    {
      title: "Cerrar sesión",
      icon: "external-link-outline",
      data: { id: "logout" },
    },
  ];
  userName: string;
  rol: string;

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private router: Router,
    private nbMenuService: NbMenuService,
    private jwtHelperService: JwtHelperService,
    private loader: LoaderService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("userName");
      const tokenData = this.jwtHelperService.decodeToken(token);
      this.userName = tokenData.name || "Guest";
      this.rol = "superUser";
      localStorage.setItem("userName", this.userName);
      localStorage.setItem("nickname", tokenData.nickname);
    }
    this.nbMenuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "user"))
      .subscribe((menuBag: NbMenuBag) => {
        if (menuBag.item.data && menuBag.item.data.id === "logout") {
          this.logOut();
          menuBag.item.data.id = "";
        }
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  logOut(): void {
    this.loader.loading = true;
    this.loader.description = "Procesando tu solicitud";
    this.authenticationService
      .signOut()
      .then((response) => {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["signin"]);
        this.loader.loading = false;
      })
      .catch((error) => {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["signin"]);
        this.loader.loading = false;
      });
  }
}
