import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IgxProgressBarModule } from "igniteui-angular";
import { BlockUIModule } from "primeng/blockui";
import { LoaderComponent } from "./loader.component";

@NgModule({
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  imports: [CommonModule, IgxProgressBarModule, BlockUIModule],
  providers: [],
})
export class LoaderModule {}
