import { DatePipe, DecimalPipe, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import es from "@angular/common/locales/es";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtHelperService, JwtModule } from "@auth0/angular-jwt";
import { NbDialogModule, NbMenuModule, NbSidebarModule, NbToastrModule, NbWindowModule } from "@nebular/theme";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { RECAPTCHA_V3_SITE_KEY } from "./core/models/tokens";
import { ReCaptchaV3Service } from "./core/services/recaptcha-v3.service";
import { LoaderModule } from "./theme/components/loader/loader.module";
import { ThemeModule } from "./theme/theme.module";

registerLocaleData(es);
export function tokenGetter() {
  return localStorage.getItem("token");
}
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    LoaderModule,
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    JwtHelperService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.keyngCache,
    },
    ReCaptchaV3Service,
    { provide: LOCALE_ID, useValue: "es-CO" },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
