import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from "@angular/common";
import { Injector, Pipe, PipeTransform } from "@angular/core";
import { BalanceStatusPipe } from "./balance-status.pipe";
import { CurrentStatusClientPipe } from "./current-status-client.pipe";
import { DocumentTypePipe } from "./document-type.pipe";
import { ExtractDataArrayPipe } from "./extract-data-array.pipe";
import { ExtractDataTreePipe } from "./extract-data-tree.pipe";
import { TagStatusPipe } from "./tag-status.pipe";

@Pipe({
  name: "dynamicPipe",
})
export class DynamicPipePipe implements PipeTransform {
  public constructor(private injector: Injector) {}

  transform(value: any, data: any, col: any, ...arg: any[]): any {
    if (!col) {
      return value;
    }
    const pipeToken = col.pipe;
    const pipeArgs = col.argsPipe;

    value = this.injector.get<ExtractDataTreePipe>(ExtractDataTreePipe).transform(value, data, col, pipeArgs);
    if (col.type === "date") {
      try {
        return this.injector.get<DatePipe>(DatePipe).transform(value, "dd/MM/yyyy hh:mm a");
      } catch (error) {
        return value;
      }
    }

    if (!pipeToken) {
      return value;
    }

    if (pipeToken === "number") {
      try {
        return this.injector.get<DecimalPipe>(DecimalPipe).transform(value);
      } catch (error) {
        return value;
      }
    }
    const MAP = {
      statusClient: CurrentStatusClientPipe,
      documentType: DocumentTypePipe,
      extractDataArray: ExtractDataArrayPipe,
      tagStatus: TagStatusPipe,
      balanceStatus: BalanceStatusPipe,
    };
    const pipeClass = MAP[pipeToken];
    const pipe = this.injector.get<PipeTransform>(pipeClass);
    if (pipeArgs) {
      return Array.isArray(pipeArgs) ? pipe.transform(value, ...pipeArgs) : pipe.transform(value, pipeArgs);
    }
    return pipe.transform(value);
  }
}
