import { Injectable } from "@angular/core";
import { interval, Observable, Subject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SessionTimerService {
  private remainSeconds$: Observable<number>;
  private remainSecondsToken$: Observable<number>;

  private count: number;
  private countToken: number;
  private timerSubscription: Subscription;
  private timerTokenSubscription: Subscription;
  private timer: Observable<number> = interval(1000);
  private timerToken: Observable<number> = interval(1000);
  private remainSeconds = new Subject<number>();
  private remainSecondsToken = new Subject<number>();

  /**
   * Observable to get session remaining time (in seconds).
   *
   * Subscribers need to unsubscribe to it before hosting element is destroyed.
   *
   * @memberof SessionTimerService
   */

  constructor() {
    this.remainSeconds$ = this.remainSeconds.asObservable();
    this.remainSecondsToken$ = this.remainSecondsToken.asObservable();
  }

  startTimerToken() {
    this.stopTimerToken();
    const date = new Date(0);
    date.setUTCSeconds(+localStorage.getItem("exp"));
    const timer = date.getTime() - new Date().getTime();
    this.countToken = Math.floor(timer / 1000);
    this.timerTokenSubscription = this.timerToken.subscribe((n) => {
      if (this.countToken > 0) {
        this.countToken--;
        this.remainSecondsToken.next(this.countToken);
      }
    });
  }

  startTimer() {
    this.stopTimer();
    this.count = environment.inactivitySecondTime;
    this.timerSubscription = this.timer.subscribe((n) => {
      if (this.count > 0) {
        this.count--;
        this.remainSeconds.next(this.count);
      }
    });
  }

  stopTimerToken() {
    if (this.timerTokenSubscription) {
      this.timerTokenSubscription.unsubscribe();
    }
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer() {
    this.startTimer();
  }

  getRemainSecondsObservable() {
    return this.remainSeconds$;
  }

  getremainSecondsTokenObservable() {
    return this.remainSecondsToken$;
  }
}
