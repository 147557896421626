import { Pipe, PipeTransform } from "@angular/core";
import { IDocumentType } from "../../pages/administrator/vehicles/models/vehicle";

@Pipe({
  name: "documentType",
})
export class DocumentTypePipe implements PipeTransform {
  typeDocuments: IDocumentType[] = [
    {
      value: "1",
      description: "C.C.",
    },
    {
      value: "2",
      description: "C.E.",
    },
    {
      value: "3",
      description: "NIT.",
    },
  ];

  transform(value: any, ...args: any[]): string {
    const typeDocumentFind = this.typeDocuments.find((typeDocument) => typeDocument.value === value);
    return typeDocumentFind ? typeDocumentFind.description : value;
  }
}
