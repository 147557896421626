/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as moment from "moment";
import { Confirmation, FilterService, MenuItem } from "primeng/api";
import { Table } from "primeng/table";
import { ExcelService } from "../../../core/services/excel.service";
import { DynamicPipePipe } from "../../pipes/dynamic-pipe.pipe";
import { ExtractDataArrayPipe } from "../../pipes/extract-data-array.pipe";
import { ExtractDataTreePipe } from "../../pipes/extract-data-tree.pipe";

@Component({
  selector: "fp-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  @ViewChild("dt") table: Table;

  @Input()
  rangeDates: any = [];

  @Input()
  title: string[];

  @Input()
  cols: any[];

  @Input()
  frozenWidth: any;

  @Input()
  loading: any[];

  @Input()
  values: any[];

  @Input()
  confirmationSwitch: Confirmation;

  @Input()
  maxDate: Date = new Date();

  @Input()
  scrollHeight: string;

  @Input()
  dataKey: string;

  @Input()
  selectionMode: string;

  @Input()
  styleClass: string;

  @Input()
  items: MenuItem[];

  @Input()
  disabledSwitch: boolean = true;

  @Input()
  disabledSwitchFrozen: boolean = true;

  @Input()
  showCreateButton: boolean = false;

  @Input()
  showUploadFile: boolean = false;

  @Input()
  showExportButton: boolean = true;

  @Input()
  showLoadMoreDataButton: boolean = false;

  @Input()
  checkboxFrozen: boolean = false;

  @Input()
  showFilterGlobal: boolean = true;

  @Input()
  showSelectColumns: boolean = true;

  @Input()
  showHeaderFilters: boolean = true;

  @Input()
  showAditionalFilter: boolean = false;

  @Input()
  labelAditionalFilter: string;

  @Input()
  optionsAditionalFilter: any[];

  @Input()
  optionLabelAditionalFilter: string;

  @Input()
  exportFilename: string;

  @Input()
  paginatorDropdownAppendTo: any = "body";

  _selectedAditionalFilter: any[];

  _field: string;

  _selectedColumns: any[];

  _selection: any;

  _selectionContextMenu: any;

  @Output() onRowSelect: EventEmitter<any> = new EventEmitter();

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @Output() selectionContextMenuChange: EventEmitter<any> = new EventEmitter();

  @Output() onContextMenuSelect: EventEmitter<any> = new EventEmitter();

  @Output() onContextMenuSelectionChange: EventEmitter<any> = new EventEmitter();

  @Output() onChangeSwitch: EventEmitter<any> = new EventEmitter();

  @Output() onCreateClick: EventEmitter<any> = new EventEmitter();

  @Output() onUploadFilesClick: EventEmitter<any> = new EventEmitter();

  @Output() onMouseoverTD: EventEmitter<any> = new EventEmitter();

  @Output() onMouseoutTD: EventEmitter<any> = new EventEmitter();

  @Output() onPanelHideAditionalFilter: EventEmitter<any> = new EventEmitter();

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  @Output() onFilterSpecial: EventEmitter<any> = new EventEmitter();

  @Output() onLoadMoreData: EventEmitter<any> = new EventEmitter();

  @Output()
  selectedAditionalFilterChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private extractDataTreePipe: ExtractDataTreePipe,
    private extractDataArrayPipe: ExtractDataArrayPipe,
    private filterService: FilterService,
    private excelService: ExcelService,
    private dynamicPipe: DynamicPipePipe,
  ) {}

  ngOnInit(): void {
    this.filterService.register("dateRangeFilter", (value, filter): boolean => {
      if (!value) {
        return false;
      }
      if (!this.rangeDates[this.field] || !this.rangeDates[this.field][0]) {
        return true;
      }
      const initial = this.rangeDates[this.field][0].getTime();
      if (this.rangeDates[this.field][0] && (!this.rangeDates[this.field] || !this.rangeDates[this.field][1])) {
        return moment(value, "YYYY-MM-DDTHH:mm").toDate().getTime() >= initial;
      }
      const final = moment(this.rangeDates[this.field][1]).endOf("day").toDate().getTime();
      return (
        moment(value, "YYYY-MM-DDTHH:mm").toDate().getTime() >= initial &&
        moment(value, "YYYY-MM-DDTHH:mm").toDate().getTime() <= final
      );
    });

    this.filterService.register("numberInBetween", (value: any, filter: [number, number]) => {
      if ((filter[0] === undefined || filter[0] === null) && (filter[1] === undefined || filter[1] === null)) {
        return true;
      }

      if (value && Array.isArray(value)) {
        value = this.extractDataArrayPipe
          .transform(value, {
            token: "balance",
            filter: "statusAccount",
            valueFilter: "Activo",
            isNumeric: true,
            formatNumeric: "1.0-0",
          })
          .replace(/\./g, "")
          .replace(/\,/g, "");
      }

      if (!value || isNaN(Number(value))) {
        return false;
      }

      if ((filter[0] || filter[0] <= 0) && (filter[1] === undefined || filter[1] === null)) {
        return Number(value) >= filter[0];
      }

      if ((filter[1] || filter[1] <= 0) && (filter[0] === undefined || filter[0] === null)) {
        return Number(value) <= filter[1];
      }

      return Number(value) >= filter[0] && Number(value) <= filter[1];
    });
  }

  exportCSV() {
    this.table.columns = this.cols;
    this.table.exportCSV();
    this.table.columns = this.selectedColumns;
  }

  loadMoreData(event: any) {
    this.onLoadMoreData.emit(event);
  }

  selectedRow(event: any) {
    event.target = event.originalEvent.target;
    this.onRowSelect.emit(event);
  }

  handleChangeSwitch(e: any, data: any) {
    this.onChangeSwitch.emit({ event: e, data });
  }

  createClick(event: any) {
    this.onCreateClick.emit(event);
  }

  uploadClick(event: any) {
    this.onUploadFilesClick.emit(event);
  }

  mouseoverTD(event: any, data: any) {
    event.data = data;
    this.onMouseoverTD.emit(event);
  }

  mouseoutTD() {
    this.onMouseoutTD.emit();
  }

  panelHideAditionalFilter() {
    this.onPanelHideAditionalFilter.emit();
  }

  disabledInputSwitch(col: any, data: any, disabledStatus: boolean) {
    if (!disabledStatus && col.fieldShowSwitch) {
      const colTemp = JSON.parse(JSON.stringify(col));
      colTemp.field = col.fieldShowSwitch;
      const validate = this.extractDataTreePipe.transform("", data, colTemp);
      return !validate;
    }
    return disabledStatus;
  }

  onSelectDate(field: string) {}

  contextMenuSelected(event: any) {
    this.onContextMenuSelect.emit(event);
  }

  contextMenuSelectionChange(event: any) {
    this.onContextMenuSelectionChange.emit(event);
  }

  filter(event: any) {
    this.onFilter.emit(event);
  }

  filterSpecial(event: any) {
    this.onFilterSpecial.emit(event);
  }

  exportExcel() {
    const columns = this.cols;
    const excelValues = [];
    this.values.forEach((value) => {
      const excelValue = {};
      columns.forEach((col) => {
        excelValue[col.header] = this.dynamicPipe.transform(value[col.field], value, col);
      });
      excelValues.push(excelValue);
    });
    this.excelService.exportAsExcelFile(excelValues, this.exportFilename);
  }
  get globalColumnsFilter() {
    return this.selectedColumns ? this.selectedColumns.map((col) => col.field) : [];
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = val;
    if (this.cols) {
      this._selectedColumns = this.cols.filter((col) => {
        let exist = false;
        val.forEach((v: any) => {
          exist = exist || v.field === col.field;
        });
        return exist;
      });
    }
  }

  @Input() get selection(): any {
    return this._selection;
  }

  set selection(val: any) {
    this._selection = val;
    this.selectionChange.emit(val);
  }

  @Input() get selectedAditionalFilter(): any {
    return this._selectedAditionalFilter;
  }

  set selectedAditionalFilter(val: any) {
    this._selectedAditionalFilter = val;
    this.selectedAditionalFilterChange.emit(val);
  }

  @Input() get selectionContextMenu(): any {
    return this._selectionContextMenu;
  }

  set selectionContextMenu(val: any) {
    this._selectionContextMenu = val;
    this.selectionContextMenuChange.emit(val);
  }

  set field(field: string) {
    this._field = field;
  }

  get field() {
    return this._field;
  }
}
