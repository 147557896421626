import { Location } from "@angular/common";
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { CommonsService } from "@core/services/commons.service";

@Component({
  selector: "fp-card-body",
  styleUrls: ["./card-body.component.scss"],
  templateUrl: "./card-body.component.html",
})
export class FpCardBodyComponent implements OnInit {
  @ContentChild(TemplateRef) tplRef: TemplateRef<any>;
  @Input() showBackgroundPeople: boolean = false;
  @Input() showBackButton: string = "visible";
  @Input() backOption: boolean = false;
  @Input() template: string;
  @Input() urlBack: string;
  @Input() close: boolean = false;
  @Input() exit: boolean = false;
  @Input() actionType: string = "redirect";
  @Input() typeDashboard: boolean = false;
  @Input() menuShow: boolean = false;
  @Output() actionClose = new EventEmitter<boolean>();

  constructor(public location: Location, private router: Router, private commonService: CommonsService) {}
  /**
   * Initial state
   */
  ngOnInit(): void {
    document.body.style.backgroundColor = "#f9f9f9";
  }
  /**
   * return Redirect
   */
  back(): void {
    if (!this.urlBack) {
      return;
    }
    this.router.navigate([this.urlBack]);
  }

  emitActionClose(): void {
    this.actionClose.emit(true);
  }
  /**
   * action to right button
   */
  rigthAction(): void {
    switch (this.actionType) {
      case "logout":
        this.commonService.logOut();
        break;
      case "redirect":
        this.router.navigate(["/home"]);
        break;
    }
  }
}
