import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import JSEncrypt from "jsencrypt";
import { throwError } from "rxjs";
import { catchError, finalize, retry, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CustomHttpParams } from "../../helpers/custom-http-params";
import { IVinculateModel } from "../../pages/administrator/vinculation-quick/models/otp-code";
import { IUser } from "../models/user";
import { AESEncryptDecryptService } from "./aes-encrypt-decrypt.service";
import { LoaderService } from "./loader.service";
import { SessionTimerService } from "./session-timer.service";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * Service to connect with AWS Cognito
 */
export class AuthenticationService {
  temporalPassword: string;
  timestamp: string;
  refreshed: boolean = true;
  encrypt = new JSEncrypt({});
  private differenceSecond: number;
  private cognitoUser: IUser;

  constructor(
    private aesEncryptDecryptService: AESEncryptDecryptService,
    private http: HttpClient,
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private loader: LoaderService,
    private sessionTimer: SessionTimerService,
  ) {
    this.encrypt.setPublicKey(environment.facilpasskeyPublic);
  }
  /**
   * Cognito Signup Integration
   *
   * @param dataSingUp params to sent a register on Cognito
   */
  public signUp(dataSingUp: IVinculateModel): Promise<any> {
    const phone = dataSingUp.personToSave.phoneNumber;
    const password = "00" + dataSingUp.personToSave.password;
    dataSingUp.personToSave.password = this.encrypt.encrypt(password) as string;
    dataSingUp.personToSave.personType = "pj";
    return this.http
      .post(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.signup,
        {
          personToSave: {
            ...dataSingUp.personToSave,
            phoneNumber: `+57${phone}`,
          },
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * Delete Cognito User
   *
   * @param nickname nickname
   */
  public deleteCognitoUser(nickname: string): Promise<any> {
    return this.http
      .delete(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.specific.admindeleteuser +
          "/" +
          nickname,
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * Cognito signIn Integration
   *
   * @param username Username
   * @param password Optional password
   */
  public async signIn(username: string, password: string): Promise<any> {
    password = this.encrypt.encrypt(password) as string;
    this.cognitoUser = await this.http
      .post<IUser>(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.login,
        {
          authParameters: {
            username,
            password,
          },
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
    localStorage.setItem("user", JSON.stringify(this.cognitoUser));
    const tokenDecode = this.jwtHelperService.decodeToken(this.cognitoUser.data.authenticationResult.idToken);

    const exp = tokenDecode.exp;
    const iat = tokenDecode.iat;
    localStorage.setItem("exp", exp);
    this.calcDifferenceTime(iat);
    return this.cognitoUser;
  }

  /**
   * Get current User
   */
  public async getCurrentAuthenticatedUser(): Promise<IUser> {
    if (this.cognitoUser) {
      return this.cognitoUser;
    } else {
      try {
        this.cognitoUser = JSON.parse(localStorage.getItem("user"));
      } catch (error) {}
      return this.cognitoUser;
    }
  }
  /**
   * SingOut
   */
  public async signOut(): Promise<any> {
    this.sessionTimer.stopTimer();
    this.sessionTimer.stopTimerToken();
    const userCognito = await this.getCurrentAuthenticatedUser();
    if (userCognito) {
      this.cognitoUser = null;
      try {
        return this.http
          .post(
            environment.api_key.security.back_url +
              environment.endpoints.usersession.init +
              environment.endpoints.usersession.specific.logout,
            {
              token: userCognito.data.authenticationResult.accessToken,
              type: "LOGOUT",
            },
            {
              params: new CustomHttpParams("api-pn-security-apiKey-token"),
            },
          )
          .pipe(retry(2))
          .toPromise();
      } catch (error) {}
    }
  }

  /**
   * Confirm Singup
   *
   * @param nickname Param to confirm singUp
   * @param code Code sended
   */
  public confirmSignUp(nickname: string, code: string): Promise<any> {
    return this.http
      .post(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.confirm,
        {
          personId: nickname,
          confirmationCode: code,
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * Method to sendOtp
   *
   * @param nickname Param to send a Otp
   */
  public sendOtp(nickname: string): Promise<any> {
    return this.http
      .get(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.resendconfirmationcode +
          "?personId=" +
          nickname,
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }

  /**
   * Get temporal String
   */
  public getTemporalPassword(): string {
    return this.temporalPassword;
  }
  /**
   *
   * @param timestampValue param to set on Congnito Singup
   */
  public setTimestampParam(timestampValue: string): void {
    this.timestamp = timestampValue;
  }

  /**
   * refresh user token
   */
  public async refreshToken() {
    if (this.refreshed) {
      this.refreshed = false;
      const userCognito = await this.getCurrentAuthenticatedUser();
      if (!userCognito) {
        return throwError({ error: "no UserCognito" });
      }
      this.http
        .post<any>(
          environment.api_key.security.back_url +
            environment.endpoints.usersession.init +
            environment.endpoints.usersession.specific.refresh,
          {
            idToken: userCognito.data.authenticationResult.idToken,
            personId: this.getUsername(),
            refreshToken: userCognito.data.authenticationResult.refreshToken,
            deviceKey: userCognito.data.authenticationResult.tokenType,
          },
          {
            headers: {
              "x-api-key": this.aesEncryptDecryptService.decrypt(environment.api_key.security.x_api_key),
              "request-uuid": this.aesEncryptDecryptService.decrypt(environment.api_key.security.request_uuid),
              "request-date": new Date().toDateString(),
              /* eslint quote-props: ["off"]*/
              ctype: "pn",
            },
          },
        )
        .pipe(
          retry(2),
          take(1),
          finalize(() => {
            this.loader.loading = false;
            this.refreshed = true;
          }),
          catchError(({ error }) => throwError(error)),
        )
        .subscribe(
          (data) => {
            this.setTokenUser(data.initiateAuthResult).finally(() => {
              this.sessionTimer.startTimerToken();
            });
          },
          (error) => {
            // sesion activa caso sesiones huerfanas
            if (error && (error.data?.code === "100582" || error.data?.code === "100581")) {
              this.cognitoUser = null;
            }
            this.signOut()
              .then(() => {
                localStorage.clear();
              })
              .finally(() => {
                this.router.navigate(["signin", "login"]);
              });
          },
        );
    }
  }
  /**
   * Method to start process to recover password
   *
   * @param nickname nickname
   */
  public async forgotPassword(personId: string): Promise<any> {
    return this.http
      .get(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.forgotpassword +
          "?personId=" +
          personId,
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * method to confirm ForgotPassword
   *
   * @param nickname nickname
   * @param code otpCode
   * @param newpassword newPassword
   */
  public async forgotPasswordSubmit(nickname: string, code: string, newpassword: string): Promise<any> {
    return this.http
      .post(
        environment.api_key.security.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.confirmforgotpassword,
        {
          personId: nickname,
          password: this.encrypt.encrypt(newpassword),
          confirmationCode: code,
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * get UserName from decodeToken
   */
  getUsername(): string {
    const decodeToken = this.jwtHelperService.decodeToken(this.cognitoUser?.data.authenticationResult.idToken);
    return decodeToken.nickname;
  }
  /**
   * get User Data on token payload
   */
  getUserData(): any {
    return this.jwtHelperService.decodeToken(this.cognitoUser?.data.authenticationResult.idToken);
  }

  public isGranted(permission: string): boolean {
    const token = localStorage.getItem("token");
    let actions: string = this.jwtHelperService.decodeToken(token).actions;
    let granted = actions.includes(permission);
    return granted;
  }

  /**
   * Save timestamp param
   */
  public getTimestampParam(): string {
    return this.timestamp;
  }

  public tokenExpired(token: string): boolean {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp + this.getDifferenceSecond();
    return new Date().getTime() / 1000 >= expiry;
  }

  /**
   * set User Token
   *
   * @param data data
   */
  private async setTokenUser(data: any) {
    const userCognito = await this.getCurrentAuthenticatedUser();
    userCognito.data.authenticationResult.accessToken = data.accessToken;
    userCognito.data.authenticationResult.expiresIn = data.expiresIn;
    userCognito.data.authenticationResult.idToken = data.idToken;
    userCognito.data.authenticationResult.tokenType = data.tokenType;
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(userCognito));
    localStorage.removeItem("token");
    localStorage.setItem("token", data.idToken);
    const tokenDecode = this.jwtHelperService.decodeToken(data.idToken);
    const exp = tokenDecode.exp;
    localStorage.removeItem("exp");
    localStorage.setItem("exp", exp);
  }

  private calcDifferenceTime(iat: number): void {
    const iatDate = new Date(iat * 1000);
    const actualDate = new Date();
    const difference = actualDate.getTime() - iatDate.getTime();
    this.differenceSecond = difference / 1000;
    localStorage.setItem("differenceTime", this.differenceSecond.toString());
  }

  private getDifferenceSecond(): number {
    if (this.differenceSecond) {
      return this.differenceSecond;
    } else {
      this.differenceSecond = Number(localStorage.getItem("differenceTime"));
    }
    return this.differenceSecond;
  }
}
