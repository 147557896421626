import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { NbAuthModule, NbDummyAuthStrategy } from "@nebular/auth";
import { NbRoleProvider, NbSecurityModule } from "@nebular/security";
import { AuthenticationInterceptorService } from "./interceptors/authentication-interceptor.service";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { LayoutService } from "./services/layout.service";
import { RoleProviderService } from "./services/role-provider.service";

const socialLinks = [
  {
    url: "https://github.com/user",
    target: "_blank",
    icon: "github",
  },
  {
    url: "https://www.facebook.com/user/",
    target: "_blank",
    icon: "facebook",
  },
  {
    url: "https://twitter.com/user",
    target: "_blank",
    icon: "twitter",
  },
];

const DATA_SERVICES = [];

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbDummyAuthStrategy.setup({
        name: "email",
        delay: 3000,
      }),
    ],
    forms: {
      login: {
        socialLinks,
      },
      register: {
        socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: "dashboard",
      },
      user: {
        parent: "guest",
      },
      supervisor: {
        parent: "guest",
        view: [
          "clients",
          "prospects",
          "treeStatusProspect",
          "vehicles",
          "tags",
          "tagsInventory",
          "tagsInventoryUpload",
          "tagsInventoryTransfer",
          "tagsInventoryTransferStatistics",
          "tagsManagement",
          "tagsManagementClients",
          "tagsManagementVehicles",
          "tagsManagementEnrolled",
          "tagsManagementDispatch",
          "reportOperations",
        ],
        download: ["clients", "vehicles"],
        status: ["clients", "prospects", "vehicles"],
      },
      agentCallCenter: {
        parent: "guest",
        view: [
          "clients",
          "prospects",
          "treeStatusProspect",
          "vehicles",
          "tags",
          "tagsManagement",
          "tagsManagementClients",
        ],
      },
      logisticsAnalyst: {
        parent: "guest",
        view: ["clients", "prospects", "treeStatusProspect", "vehicles"],
        download: ["clients", "prospects", "vehicles"],
        create: "vehicles",
        edit: "vehicles",
      },
      inventoryTags: {
        parent: "guest",
        view: [
          "tags",
          "tagsInventory",
          "tagsInventoryUpload",
          "tagsInventoryTransfer",
          "tagsInventoryTransferStatistics",
        ],
      },
      logistics: {
        parent: "guest",
        view: [
          "clients",
          "prospects",
          "treeStatusProspect",
          "vehicles",
          "tags",
          "tagsInventory",
          "tagsInventoryUpload",
          "tagsManagement",
          "tagsManagementClients",
          "tagsManagementVehicles",
          "tagsManagementEnrolled",
          "tagsManagementDispatch",
        ],
      },
      operative: {
        parent: "guest",
        view: [
          "clients",
          "prospects",
          "treeStatusProspect",
          "vehicles",
          "tags",
          "tagsManagement",
          "tagsManagementClients",
          "tagsManagementVehicles",
          "tagsManagementEnrolled",
          "tagsManagementDispatch",
          "reportOperations",
          "pendingCancelation",
        ],
        download: ["clients", "vehicles", "prospects"],
        create: ["clients", "vehicles"],
        edit: ["clients", "vehicles", "prospects", "vehiclesRecategorize"],
        status: ["clients", "prospects", "vehicles"],
      },
      pointAttention: {
        parent: "guest",
        view: [
          "clients",
          "prospects",
          "treeStatusProspect",
          "vehicles",
          "tags",
          "tagsManagement",
          "tagsManagementClients",
        ],
        create: ["clients"],
      },
      customerService: {
        parent: "guest",
        view: [
          "clients",
          "prospects",
          "treeStatusProspect",
          "vehicles",
          "vehiclesRecategorize",
          "tags",
          "tagsManagement",
          "tagsManagementClients",
        ],
        download: ["vehicles"],
        create: ["clients", "vehicles"],
        edit: ["vehicles", "prospects"],
      },
      operativeTAGS: {
        parent: "operative",
        view: ["tags", "tagsInventory", "tagsInventoryTransfer", "tagsInventoryTransferStatistics"],
      },
      superUser: {
        view: "*",
        download: "*",
        create: "*",
        edit: "*",
        status: "*",
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: RoleProviderService,
  },
  LayoutService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptorService,
    multi: true,
  },
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
