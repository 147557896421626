import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import {
  NbAccordionModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbIconModule,
  NbJSThemeOptions,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule,
  NbUserModule,
} from "@nebular/theme";

import { ConfirmationService, MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BlockUIModule } from "primeng/blockui";
import { CalendarModule } from "primeng/calendar";
import { CarouselModule } from "primeng/carousel";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { DragDropModule } from "primeng/dragdrop";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MenubarModule } from "primeng/menubar";
import { MultiSelectModule } from "primeng/multiselect";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { SidebarModule } from "primeng/sidebar";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";

import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { FpCardModule } from "./components/card/card.module";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { LayoutComponent } from "./components/layout/layout.component";
import { PageMessageModule } from "./components/page-message/page-message.module";
import { SessionExpirationAlertModule } from "./components/session-expiration-alert-modal/session-expiration-alert.module";
import { TableComponent } from "./components/table/table.component";
import { TemplateBallsComponent } from "./components/template/balls/template-balls.component";
import { ValidatorRegExpDirective } from "./directives/validator-regexp.directive";
import { BalanceStatusPipe } from "./pipes/balance-status.pipe";
import { CurrentStatusClientPipe } from "./pipes/current-status-client.pipe";
import { DocumentTypePipe } from "./pipes/document-type.pipe";
import { DynamicPipePipe } from "./pipes/dynamic-pipe.pipe";
import { ExtractDataArrayPipe } from "./pipes/extract-data-array.pipe";
import { ExtractDataTreePipe } from "./pipes/extract-data-tree.pipe";
import { KeysPipe } from "./pipes/keys.pipe";
import { TagStatusPipe } from "./pipes/tag-status.pipe";
import { DEFAULT_THEME } from "./styles/theme.default";

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbAccordionModule,
  NbCardModule,
];

const P_MODULES = [
  TableModule,
  MultiSelectModule,
  CalendarModule,
  DropdownModule,
  ProgressBarModule,
  InputTextModule,
  TabViewModule,
  SidebarModule,
  InputSwitchModule,
  TooltipModule,
  ConfirmDialogModule,
  InputTextareaModule,
  InputNumberModule,
  CheckboxModule,
  ContextMenuModule,
  FileUploadModule,
  DragDropModule,
  DialogModule,
  RadioButtonModule,
  BlockUIModule,
  ToastModule,
  OverlayPanelModule,
  AutoCompleteModule,
  MenubarModule,
  CarouselModule,
];

const FP_MODULES = [SessionExpirationAlertModule, PageMessageModule, FpCardModule];

const MT_MODULE = [MatExpansionModule, MatStepperModule, MatIconModule];

const COMPONENTS = [HeaderComponent, FooterComponent, LayoutComponent, TableComponent, TemplateBallsComponent];

const PIPES = [
  CurrentStatusClientPipe,
  DynamicPipePipe,
  DocumentTypePipe,
  ExtractDataArrayPipe,
  ExtractDataTreePipe,
  KeysPipe,
  TagStatusPipe,
  BalanceStatusPipe,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...FP_MODULES, ...NB_MODULES, ...P_MODULES, ...MT_MODULE],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ValidatorRegExpDirective,
    ...PIPES,
    ...COMPONENTS,
    ...FP_MODULES,
    ...NB_MODULES,
    ...P_MODULES,
    ...MT_MODULE,
  ],
  declarations: [...COMPONENTS, ...PIPES, ValidatorRegExpDirective],
  providers: [ConfirmationService, MessageService, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: "default",
          },
          [DEFAULT_THEME as NbJSThemeOptions],
        ).providers,
      ],
    };
  }
}
