import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, Routes } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NbAccessChecker } from "@nebular/security";
import * as modules from "../../assets/config/modules.json";
import { SessionTimerService } from "../core/services/session-timer.service";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  routesPages: Routes = (modules as any).default;

  constructor(
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private accessChecker: NbAccessChecker,
    private sessionTimer: SessionTimerService,
  ) {}
  /**
   * Activate route
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = localStorage.getItem("token");
    this.sessionTimer.resetTimer();
    if (token && token !== "" && typeof token === "string" && !this.jwtHelperService.isTokenExpired(token)) {
      const routerFind: Route = this.routesPages.find((child) => state.url === child.path);

      let granted = false;
      if (routerFind && routerFind.data && routerFind.data.module) {
        granted = await this.accessChecker.isGranted("view", routerFind.data.module).toPromise();
        if (!granted) {
          this.router.navigate(["pages"]);
        }
      }
      return Promise.resolve(granted);
    } else {
      this.router.navigate(["signin"]);
      return Promise.resolve(false);
    }
  }
}
