import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ActiveSessionGuard } from "./guards/active-session.guard";
import { AuthGuard } from "./guards/auth.guard";

export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "signin",
    loadChildren: () => import("./signin/signin.module").then((m) => m.SignInModule),
    canActivate: [ActiveSessionGuard],
  },
  { path: "", redirectTo: "signin", pathMatch: "full" },
  { path: "**", redirectTo: "signin" },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
})
export class AppRoutingModule {}
