import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "statusTAG",
})
export class TagStatusPipe implements PipeTransform {
  tagStatus: any[] = [
    {
      value: 1,
      description: "Activo",
    },
    {
      value: 2,
      description: "Inactivo",
    },
    {
      value: 3,
      description: "Disociado",
    },
  ];

  transform(value: any, ...args: any[]): string {
    const tagStatusFind = this.tagStatus.find((tagStatu) => tagStatu.value === value);
    return tagStatusFind ? tagStatusFind.description : value;
  }
}
