<p-contextMenu #cm [model]="items" appendTo="body"></p-contextMenu>
<p-table #dt [columns]="selectedColumns" [selectionMode]="selectionMode" [(selection)]="selection" [value]="values"
    [dataKey]="dataKey" [scrollHeight]="scrollHeight" [scrollable]="true" [rows]="10" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10,25,50]" scrollDirection="both" [loading]="loading" [styleClass]="'fp-datatable'"
    [paginator]="true" [paginatorDropdownAppendTo]="paginatorDropdownAppendTo" (onRowSelect)="selectedRow($event)"
    [(contextMenuSelection)]="selectionContextMenu" (contextMenuSelectionChange)="contextMenuSelectionChange($event)"
    [contextMenu]="cm" (onContextMenuSelect)="contextMenuSelected($event)" (onFilter)="filter($event)"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [filterDelay]="0"
    [globalFilterFields]="globalColumnsFilter" [exportFilename]="exportFilename" [resizableColumns]="true">
    <ng-template pTemplate="caption">
        <div class="table-header">
            {{title}}
            <div class="p-d-flex">
                <p-multiSelect class="p-mr-2" [options]="optionsAditionalFilter"
                    [optionLabel]="optionLabelAditionalFilter" dropdownIcon="pi pi-filter" *ngIf="showAditionalFilter"
                    appendTo="body" [(ngModel)]="selectedAditionalFilter" selectedItemsLabel="{0} Filtros Seleccionados"
                    [style]="{minWidth: '80px'}" [placeholder]="labelAditionalFilter" [filter]="false"
                    (onPanelHide)="panelHideAditionalFilter()" maxSelectedLabels="2">
                </p-multiSelect>
                <span class="p-input-icon-left p-mr-2" *ngIf="showFilterGlobal">
                    <em class="pi pi-search"></em>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Buscar en tabla" />
                </span>
                <p-multiSelect class="p-mr-2" [options]="cols" optionLabel="header" dropdownIcon="pi pi-sliders-h"
                    *ngIf="showSelectColumns" appendTo="body" [(ngModel)]="selectedColumns" selectedItemsLabel="{0}"
                    [style]="{minWidth: '50px'}" placeholder="Columnas" [displaySelectedLabel]="false" [filter]="false">
                </p-multiSelect>
                <button type="button" class="p-mr-2" icon="pi pi-plus" iconPos="left" style="color: white" pButton label="Cargar Archivos"
                    *ngIf="showUploadFile" (click)="uploadClick($event)"></button>

                <button type="button" icon="pi pi-plus" iconPos="left" style="color: white" pButton label="Crear"
                    *ngIf="showCreateButton" (click)="createClick($event)"></button>
            </div>
        </div>
    </ng-template>


    <ng-template pTemplate="header" let-columns>
        <tr>
            <th id="frozenCheckBox" style="width: 3rem" pFrozenColumn *ngIf="checkboxFrozen">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let col of columns; let i = index">
                <th [pSortableColumn]="col.field" [style]="{width:col.width}" id="header1" pFrozenColumn
                    [frozen]="col.frozen">{{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </ng-container>

        </tr>
        <tr *ngIf="showHeaderFilters">
            <th id="frozenCheckBox2" style="width: 3rem" pFrozenColumn pResizableColumn *ngIf="checkboxFrozen"
                alignFrozen="left">
            </th>
            <ng-container *ngFor="let col of columns; let i = index">
                <th scope="col" [style]="{width:col.width}" pFrozenColumn [frozen]="col.frozen" alignFrozen="left">
                    <div *ngIf="!col.special; else special">
                        <input pInputText type="text"
                            *ngIf="(!col.type  ||  col.type === 'text' || col.type === 'text-mouse-event')"
                            [(ngModel)]="col.valueFilter"
                            (keydown.enter)="col.filterService ? dt.filter($event.target.value, col.field, col.matchMode || 'contains') : undefined"
                            (input)="!col.filterService ? dt.filter($event.target.value, col.field, col.matchMode || 'contains') : undefined"
                            [disabled]="col.disabled" placeholder="Buscar por {{col.header}}" class="p-column-filter"
                            pTooltip="Presione enter para buscar" tooltipPosition="bottom"
                            [tooltipDisabled]="!col.filterService">
                    </div>

                    <ng-template #special>
                        <input pInputText type="number" [(ngModel)]="col.valueFilter"
                            (keydown.enter)="filterSpecial(col.valueFilter)" placeholder="Buscar por {{col.header}}"
                            class="p-column-filter" pTooltip="Presione enter para buscar" tooltipPosition="bottom">
                    </ng-template>

                    <p-calendar [(ngModel)]="rangeDates[col.field]" *ngIf="col.type === 'date'" appendTo="body"
                        (onSelect)="field = col.field; dt.filter($event, col.field, 'dateRangeFilter')"
                        (onClearClick)="dt.filter('', col.field, 'equals')" [showButtonBar]="true" selectionMode="range"
                        placeholder="Buscar rango {{col.header}}" [readonlyInput]="true" dateFormat="dd/mm/yy"
                        [inputId]="'rangeheader'+col.field" [disabled]="col.disabled">
                    </p-calendar>

                    <p-dropdown [showClear]="true" [options]="col.options"
                        (onChange)="dt.filter($event.value, col.field, 'equals')" appendTo="body"
                        styleClass="p-column-filter" placeholder="Filtrar {{col.header}}" *ngIf="col.type === 'switch'"
                        [disabled]="col.disabled" [virtualScroll]="true" itemSize="30">

                    </p-dropdown>

                    <div *ngIf="col.type === 'number'" class="p-d-flex">
                        <p-inputNumber [inputId]="'initial'+col.field" class="p-mr-2" [(ngModel)]="col.start"
                            [inputStyle]="{width: '56px'}"
                            (onInput)="dt.filter([$event.value, col.end], col.field, 'numberInBetween')"
                            placeholder="Inicial" [disabled]="col.disabled">
                        </p-inputNumber>
                        <p-inputNumber [inputId]="'final'+col.field" [(ngModel)]="col.end"
                            [inputStyle]="{width: '56px'}"
                            (onInput)="dt.filter([col.start, $event.value], col.field, 'numberInBetween')"
                            placeholder="Final" [disabled]="col.disabled">
                        </p-inputNumber>
                    </div>
                </th>
            </ng-container>

        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data let-columns="columns">
        <tr [pContextMenuRow]="data" [pContextMenuRowDisabled]="!items" [pSelectableRowDisabled]="checkboxFrozen"
            [pSelectableRowDblClick]="data">
            <td *ngIf="checkboxFrozen" pFrozenColumn>
                <p-tableCheckbox [value]="data"></p-tableCheckbox>
            </td>
            <ng-container *ngFor="let col of columns; let i = index">
                <td *ngIf="(col.type === 'text' || !col.type)" pFrozenColumn [frozen]="col.frozen" alignFrozen="left"
                    [style]="'text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width:'+col.width+';z-index: '+(col.frozen? '1': '0')">
                    <span [pTooltip]="data[col.field] | dynamicPipe:data:col" tooltipPosition="top">{{data[col.field] |
                        dynamicPipe:data:col}}</span>
                </td>
                <td [style]="'text-decoration: underline;cursor: pointer;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width:'+col.width+';z-index: '+(col.frozen? '1': '0')"
                    *ngIf="col.type === 'text-mouse-event'" pFrozenColumn [frozen]="col.frozen" alignFrozen="left">
                    <span (mouseover)="mouseoverTD($event, data)" (mouseout)="mouseoutTD()">{{data[col.field] |
                        dynamicPipe:data:col}}</span>
                </td>
                <td *ngIf="col.type === 'date'" pFrozenColumn [frozen]="col.frozen" alignFrozen="left"
                    [style]="'width:'+col.width+';z-index: '+(col.frozen? '1': '0')">
                    {{data[col.field] | dynamicPipe:data:col}}
                </td>
                <td *ngIf="col.type === 'switch'" pFrozenColumn [frozen]="col.frozen" alignFrozen="left"
                    [style]="{width:col.width}">
                    <p-inputSwitch [(ngModel)]="data[col.field]" (onChange)="handleChangeSwitch($event, data)"
                        [disabled]="disabledInputSwitch(col, data, disabledSwitch)">
                    </p-inputSwitch>
                </td>
                <td *ngIf="col.type === 'number'" style="text-align: center;" [style]="{width:col.width}">
                    {{data[col.field] | dynamicPipe:data:col}}
                </td>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
        <div *ngIf="showExportButton && values && values.length > 0" class="p-d-flex">
            <button type="button" *ngIf="false" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()"
                style="color:white" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" style="color:white"
                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
    </ng-template>
    <ng-template pTemplate="paginatorright">
        <div *ngIf="showLoadMoreDataButton && values && values.length > 0">
            <button type="button" pButton pRipple icon="pi pi-fast-forward" (click)="loadMoreData($event)"
                label="Ver Más" class="p-mr-2 p-button-outlined p-button-info" pTooltip="Cargar más registros"
                tooltipPosition="bottom"></button>
        </div>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6">No existen registros.</td>
        </tr>
    </ng-template>
</p-table>