import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { CommonsService } from "../../../../core/services/commons.service";

@Component({
  selector: "fp-template-balls",
  templateUrl: "./template-balls.component.html",
  styleUrls: ["./template-balls.component.scss"],
})
export class TemplateBallsComponent implements OnInit {
  @Input()
  containerTemplate: TemplateRef<any>;

  @Input()
  showLogo: boolean = true;

  @Input()
  navigateURL: string;

  constructor(private commons: CommonsService) {}

  ngOnInit(): void {}

  goBackUrl() {
    if (this.navigateURL) {
      this.commons.navigate(this.navigateURL);
    }
  }
}
