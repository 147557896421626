import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "balanceStatus",
})
export class BalanceStatusPipe implements PipeTransform {
  status: any[] = [
    {
      value: 1,
      description: "Con saldo",
    },
    {
      value: 2,
      description: "Sin saldo",
    },
    {
      value: 3,
      description: "Saldo bajo",
    },
  ];

  transform(value: any, ...args: any[]): string {
    const statusFind = this.status.find((status) => status.value === value);
    return statusFind ? statusFind.description : value;
  }
}
