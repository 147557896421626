import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import { take } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { ReCaptchaV3Service } from "./recaptcha-v3.service";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class CommonsService {
  timePause: number = 2000;
  /**
   * Constructor
   *
   * @param authenticationService AuthenticationService
   * @param router Router
   * @param http Http
   * @param vehiclesFacade VehiclesFacade
   * @param tagFacade TagFacade
   * @param activationTagService ActivationTagService
   */
  constructor(
    private router: Router,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authenticationService: AuthenticationService,
  ) {}
  /**
   * Logout Method
   */
  async logOut(flag = true, callService = true): Promise<any> {
    if (callService) {
      await this.authenticationService.signOut();
    }
    localStorage.clear();
    if (flag) {
      this.router.navigate(["signin", "login"]);
    }
  }
  /**
   * get Documents to Download
   *
   * @param urls urls
   */
  async getDocumentDownload(urls: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    try {
      const name = urls.split(".com/")[1];
      const resp: any = await this.http
        .get(urls, {
          headers,
          responseType: "blob" as "json",
        })
        .toPromise();
      const blob = new Blob([resp], {
        type: "application/pdf;charset=utf-8",
      });
      saveAs(blob, name);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Generate promise for each url
   */
  async generatorPromiseDownload(urls: string[]) {
    let documentsDownloadCopy = JSON.parse(JSON.stringify(urls));
    const documents = [];
    documents.push(
      new Promise((resolve, reject) => {
        resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
      }),
    );
    if (urls.length > 1) {
      documentsDownloadCopy = documentsDownloadCopy.splice(1);
      documents.push(
        new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
          }, 2000);
        }),
      );
    }
    const resolvePromises = await Promise.all(documents);

    return this.compareValues(resolvePromises);
  }

  /**
   * Compare answers to all promises
   */
  compareValues(downloads: boolean[]) {
    let index: number = 0;
    for (const download of downloads) {
      /* eslint no-unused-expressions: "off"*/
      /* eslint @typescript-eslint/no-unused-expressions: "off"*/
      download === false ? (index = index - 1) : (index = index + 1);
    }
    return index === -downloads.length ? false : true;
  }

  /**
   * Redirect to principal dashboard administration
   */
  redirectToPages(): void {
    this.router.navigate(["/pages"]);
  }
  /**
   * Navigate ti url
   *
   * @param url
   */
  navigate(urlSegment: string): void {
    this.router.navigate([urlSegment]);
  }
  /**
   * get Token Information
   */
  async getTokenInfo(): Promise<any> {
    return this.authenticationService.getUserData();
  }
  /**
   * get Ng Chapcha Info
   *
   * @param attemps intentos
   */
  async getTokenNgCaptcha(attemps: number = 1): Promise<any> {
    let token = localStorage.getItem("captcha");
    if (attemps < 5) {
      try {
        token = await this.recaptchaV3Service.execute("importantAction").pipe(take(1)).toPromise();
        localStorage.setItem("captcha", token);
      } catch (error) {
        await this.getTokenNgCaptcha(attemps + 1);
      }
    }
  }
  /**
   * getAccountId from storage service
   */
  async getAccountIdRegisteredSingup(): Promise<any> {
    return localStorage.getItem("accountId");
  }

  validationVerificationDigit(nitAdjusted: string): string {
    const lengthAdjusted = 15 - nitAdjusted.length;
    for (let index = 0; index < lengthAdjusted; index++) {
      nitAdjusted = "0" + nitAdjusted;
    }
    let digito: number = 0;
    const multiplicacion = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3];
    for (let index = 0; index < nitAdjusted.length; index++) {
      digito = digito + Number(nitAdjusted.substring(index, index + 1)) * multiplicacion[index];
    }
    let residue = digito % 11;
    switch (residue) {
      case 0:
        residue = 0;
        break;
      case 1:
        residue = 1;
        break;
      default:
        residue = 11 - residue;
        break;
    }
    return residue.toString();
  }
}
