import { Pipe, PipeTransform } from "@angular/core";
import * as currentsStatus from "../../../assets/config/status/current-status-client.json";
@Pipe({
  name: "currentStatusClient",
})
export class CurrentStatusClientPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    const statusCurrent: any = currentsStatus;
    const listStatus: any[] = statusCurrent.default;
    try {
      return listStatus.find((status) => status.code.split("_")[0] === value.split("_")[0]).description;
    } catch (error) {
      return "";
    }
  }
}
