import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, of } from "rxjs";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class ActiveSessionGuard implements CanActivate {
  constructor(private router: Router, private jwtHelperService: JwtHelperService) {}
  /**
   * Method to validate route active
   */
  canActivate(): Observable<boolean> {
    return of(this.verifyToken());
  }

  verifyToken(): boolean {
    const token = localStorage.getItem("token");
    if (!token || token === "" || this.jwtHelperService.isTokenExpired(token)) {
      return true;
    } else {
      this.router.navigate(["pages"]);
    }
  }
}
