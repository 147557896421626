import { Injectable } from "@angular/core";

import { JwtHelperService } from "@auth0/angular-jwt";
import { NbRoleProvider } from "@nebular/security";

import { of as observableOf } from "rxjs";

@Injectable()
export class RoleProviderService implements NbRoleProvider {
  readonly DEFAULT_ROL = "guest";
  constructor(private jwtHelperService: JwtHelperService) {}

  getRole() {
    const tokenEncode = localStorage.getItem("token");
    const token = this.jwtHelperService.decodeToken(tokenEncode);
    const roleString = "custom:role";
    return !this.jwtHelperService.isTokenExpired(tokenEncode) && token[roleString]
      ? observableOf("superUser")
      : observableOf(this.DEFAULT_ROL);
  }
}
