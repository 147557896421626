export const environment = {
  production: false,
  swpwa: true,
  endpoints: {
    clients: {
      init: "/clients",
      specific: {
        api: "/api",
        apiFilter: "/api/filter",
        active: "/active",
        pendingCancelation: "/api/accounts/pending-cancelation",
        clientCancelation: "/api/client-cancelation",
        currentStatus: "/api/current-status",
        client: "/client",
      },
    },
    commons: {
      init: "/commons",
      specific: {
        cities: "/cities",
      },
    },
    vehicles: {
      init: "/vehicles",
      specific: {
        categories: "/categories",
        create: "",
        uploadCSVFile: "/core/upload",
        v2: "/v2",
      },
    },
    vehicle: {
      init: "/vehicle",
      specific: {
        update: "/update-vehicle-details",
      },
    },
    updateVehicle: {
      init: "/update_vehicle_status",
    },
    tags: {
      init: "/tags",
      specific: {
        uploadCSVFile: "/upload/fileUpload",
        createReservation: "/reservation",
        makeConfirmation: "/confirmation",
        tranferTags: "/transfer",
        dataUploads: "",
        hold: "/hold",
        holdstats: "/hold/stats",
        file: "/file",
        types: "/types",
        shippingAddress: "/shipping_address",
        active: "/active",
        replaceTag: "/back-office/replace-tags",
        termsAndConditions: "/terms_and_conditions",
        pendingDispatch: "/pending-dispatch",
        dissociation: "/dissociation",
      },
    },
    movements: {
      init: "/movements",
      specific: {
        rechargue: "/rechargue",
        adjust: "/adjust",
      },
    },
    prospect: {
      init: "/prospects",
      specific: {
        identificationTypes: "/identificationtypes",
        validate: "/validate",
        documents: "/documents",
        habeasdata: "/habeasdata",
      },
    },
    vehicleTag: {
      init: "/tags",
      specific: {
        data: "/vehicle-tag",
      },
    },
    letters: {
      init: "/letters",
      specific: {
        dispatch: "/dispatch",
      },
    },
    vehicleCategories: {
      init: "/vehicle-categories",
      specific: {
        postpaid: "/postpaid",
        prepaid: "/prepaid",
      },
    },
    agreements: {
      init: "/agreements",
    },
    autoenrollment: {
      init: "/autoenrollment",
      specific: {
        tag: "/tag",
      },
    },
    negations: {
      init: "/negations",
    },
    usersession: {
      init: "/usersession",
      specific: {
        signup: "/signup",
        login: "/login",
        refresh: "/refresh",
        logout: "/logout",
        forgotpassword: "/forgotpassword",
        confirmforgotpassword: "/confirmforgotpassword",
        confirm: "/confirm",
        resendconfirmationcode: "/resendconfirmationcode",
        admindeleteuser: "/admindeleteuser",
      },
    },
    shared: {
      init: "/shared/api",
      specific: {
        operator: "/operator",
      },
    },
    admin: {
      init: "/admin",
      specific: {
        userUpdate: "/user_update",
      },
    },
    transactions: {
      init: "/transactions",
      specific: {
        listType: "/orphan/list-type",
        orphans: "/orphans?",
      },
    },
    transaction: {
      init: "/transaction",
    },
    massiveUploads: {
      init: "/massive-uploads",
      specific: {
        files: "/files",
      },
    },
    core: {
      init: "/core",
      specific: {
        consultReport: "/consultReport",
        getUserData: "/customer/details/get-user-data",
        updateUserData: "/customer/details/update-user-data",
      },
    },
    requesReport: {
      init: "/core",
      specific: {
        report: "/report",
      },
    },
    reportAccountBalance: {
      init: "/jobs-batch-launcher",
      specific: {
        report: "/job-balances-report-launch",
      },
    },
    tokenId: {
      init: "/dev/core",
      specific: {
        preSign: "/pre-sign-url/",
        presignUrl: "/pre-signed-URL",
      },
    },
    fileReport: {
      init: "/dev",
      specific: {
        file: "/files/download",
      },
    },
    concession: {
      init: "/concession",
    },
    SIGT: {
      init: "/sigt",
      specific: {
        plateAssigned: "/plate/assigned",
      },
    },
    v1: {
      init: "/v1",
      specific: {
        pass: "/pasos",
        test: "/pruebas",
        rel: "/rel/1",
      },
    },
    a5RequestProof: {
      init: "/a5-request-proof-api",
    },
    request: {
      init: "/request",
      specific: {
        proof: "/proof",
      },
    },
    password: {
      init: "/password",
      specific: { validate: "/validate" },
    },
    users: {
      init: "/users",
      specific: {
        users: "/users",
        user: "/user",
      },
    },
    requests: {
      init: "/requests",
      specific: {
        tags: "/tags?",
      },
    },
    supporList: {
      init: "/list",
      specific: {
        support: "/support?",
      },
    },
  },
  api_key: {
    clients: {
      back_url: "https://fap-toll-dev-api-clients.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX1+b6lwtU7d0aYLZ/8HXxq4fVKkBhDD03u2rPoL0ryyFykSEVrjIxwhgedBbDuEifEaUrWJxInispA==",
      request_uuid:
        "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==36373836663063632d623934352d313165612d623364652d303234326163313330303034",
    },
    two: {
      back_url: "https://mm5jux0hll.execute-api.us-east-2.amazonaws.com/dev",
      x_api_key:
        "U2FsdGVkX1/Z5Ctf27vo7NCIXB9kKDlq38v59PMMRIAzgGmtBd52s4FHZMWsWQmZgH1cauFWisrqBksosKNGqQ==79394576514c706b6c4534534a7073456c344d704f35624a61757543346a6843596159794864306a",
      request_uuid:
        "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==36373836663063632d623934352d313165612d623364652d303234326163313330303034",
    },
    vehicles: {
      back_url: "https://fap-toll-dev-api-vehicles.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX1+xZhLnKuqvbGFWbCu/GwD30g/ZZ3VPO18VTIQaws4mYi0Y1XCtLK8sV7bSjJouQGmP893Qepn4Zg==",
      request_uuid: "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==",
    },
    commons: {
      back_url: "https://fap-commons-dev-api.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX19V8gdtnyOAayiMVI4AF05OCpZevs7y7dOaIR/Y4FwgyV5hUMWSdRSmGl+nVPLvHsH6HBanCOkFuw==",
    },
    tollPublic: {
      back_url: "https://fap-toll-dev-api-public.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX19F9k9fztI8L8v8yzF8rRhjegV67vI7CmgAytMmZaYgbfZXLpRePII4HF5qzPMvLHvTzwSHvnYRMw==",
      request_uuid: "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==",
    },
    movements: {
      back_url: "https://fap-toll-dev-api-movements-accounting.avaldigitallabs.com",
    },
    security: {
      back_url: "https://fap-toll-dev-api-security.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX18GMCEPSre4kaWlCkII4KqlZh1peVKypc51TQXvNfPbxHA2DK24cB64OS8jJqzyftwSqYbM6gGLVw==",
      request_uuid: "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==",
    },
    shared: {
      back_url: "https://fap-commons-dev-api-shared-services.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX18Tzdk9jnHzzvOgUIIGgwfk5LWdV8sNXj/jYtWW88hJBPuBMval7nm7RgFctFYVQbBj13KqAhjfbw==",
      request_uuid: "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==",
    },
    webTag: {
      back_url: "https://fap-commons-dev-api-web-tags.avaldigitallabs.com",
      x_api_key: "U2FsdGVkX183NFACkKZhRMiXjHJblJzcMYI4rvT3gqJnw7a4ORXYmcUgnyBszMVCuh07H1tljVfd8V3GahrNvw==",
    },
    reportRequest: {
      back_url: "https://22v8f6xn6a.execute-api.us-east-2.amazonaws.com",
      // back_url: "https://fap-commons-dev-api-reports.avaldigitallabs.com",
      x_api_key: "TFNv71fqL56o104YQdPkB65NtF9rd9Cl8llvmkCD",
    },
    backoffice: {
      back_url: "https://fap-commons-dev-api-backoffice.avaldigitallabs.com",
      x_api_key: "lmOtid7OXg82GaR51lG5g6N0Hyd2wdfpZYqb6Pb0",
    },
    priv: {
      back_url: "https://fap-toll-dev-api-priv.avaldigitallabs.com",
      x_api_key: "9XTbWQcip73660kZ6iMv4tCHmf781ky3OUBbvXMe",
      request_uuid: "U2FsdGVkX18OZZieHLsN04WG2Cpgl6txZIhC1uH1TS2cc5W7WTN8XUr3aSVCGAUEOHmAUqrDONsJiLVdHFJj/Q==",
    },
    transversalServices: {
      back_url: "https://transversal-services-facilpass-api-dev.avaldigitallabs.com",
    },
    tollServices: {
      back_url: "https://toll-services-facilpass-api-dev.avaldigitallabs.com",
    },
  },
  facilpasskey: "F1p1c7lp1ssK3y",
  facilpasskeyPublic:
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj+qTIt2Do5erh+8Uw1RyFgMp18Q8iQbcgcMqmomHmYhRTnWXAATIfFgJ8xXitZnZ+oWIo1MrzM8QllwY8i8lTHDbepTvFLNsm72wv8lEqicRO+wAGZdbTZhXGg7wVN2Cd56pjpug8wuboRAkH6FPFtOuQLs8GumyREE/qiOzXd0Afw6/P/JGX5/kShWl+9FGlyfunR+rmu9bu33tZkNxCa5h4ZwZ0CQp8x6smr5b58RzDmeo6jeJQ6aiTbVbJsoBpM37OEfxX9WxCYabbC33llAam8xy8YmfWt25mZIcQxC9HrfvcGr3rJ3w47w/fo4EeTKbWzUZ0HxlRqUAaxj+9wIDAQAB",
  amplify: {
    Auth: {
      identityPoolId: "",
      region: "us-east-2",
      userPoolId: "us-east-2_K1WRObBlx",
      userPoolWebClientId: "3mfdvgphdv4b734ahe6es7c0lr",
    },
  },
  keyngCache: "6LeCeTkaAAAAAB68AWaQWH-F_9GPbpe4M3KxB0iX",
  inactivitySecondTime: 300,
  accountIdReport: "0010037",
  intermediatorId: "10151",
  subscriptionKey: "f24f3646636a4c0d978e2951f3066864",
  defaultCompany: {
    nit: "10000",
    dv: "3",
  },
  buckets: {
    confirmationTag: "fap-commons-dev-s3-confirmation-tags",
  },
};
