<div class="form">
    <div class="content-form">
        <div class="content-balls">
            <div class="circulo2"></div>
            <div class="circulo"></div>
        </div>
        <div class="circulo-cuarto"></div>
        <div class="content-balls2">
            <div class="circulo3"></div>
            <div class="circulo4"></div>
        </div>
    </div>
</div>
<div class="circulo-borde"></div>
<nb-layout windowMode>
    <nb-layout-header>
        <app-header></app-header>
    </nb-layout-header>

    <nb-sidebar  class="menu-sidebar" tag="menu-sidebar" responsive>
        <a class="logo" href="#"><img src="assets/images/nav-menu/logoFp.svg" alt="logo2"></a>
        <ng-content select="nb-menu"></ng-content>
    </nb-sidebar>

    <nb-layout-column>

        <ng-content select="router-outlet"></ng-content>
        <ng-content select="fp-loader"></ng-content>
        <ng-content select="fp-session-expiration-alert"></ng-content>


    </nb-layout-column>
</nb-layout>