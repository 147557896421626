import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IgxProgressBarModule } from "igniteui-angular";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { SessionExpirationAlertModalComponent } from "./session-expiration-alert-modal.component";

@NgModule({
  imports: [CommonModule, DialogModule, ButtonModule, IgxProgressBarModule],
  declarations: [SessionExpirationAlertModalComponent],
  providers: [],
  exports: [SessionExpirationAlertModalComponent],
})
export class SessionExpirationAlertModule {}
