import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "extractDataTree",
})
export class ExtractDataTreePipe implements PipeTransform {
  constructor() {}
  transform(value: string, data: any, col: any, ...args: any[]): string {
    if (!data || !col.field) {
      return value;
    }
    return this.getElementFromTree(data, col.field.split("."), value);
  }

  getElementFromTree(treePart: any, path: any, value: string) {
    const prop = path.shift();
    if (!path.length) {
      return treePart[prop] ? treePart[prop] : undefined;
    }
    let nextTreePart;
    if (isNaN(prop) && treePart[prop]) {
      nextTreePart = treePart[prop];
    }

    if (!nextTreePart) {
      return value;
    }
    if (path.length) {
      return this.getElementFromTree(nextTreePart, path, value);
    }
    return nextTreePart;
  }
}
