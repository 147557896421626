import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { PageMessageComponent } from "./page-message.component";
@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  exports: [PageMessageComponent],
  declarations: [PageMessageComponent],
})
export class PageMessageModule {}
