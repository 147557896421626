<div class="form">
    <div class="content-form">
        <div class="content-balls">
            <div class="circulo2"></div>
            <div class="circulo"></div>
        </div>
        <div class="circulo-cuarto"></div>
        <div class="content-balls2">
            <div class="circulo3"></div>
            <div class="circulo4"></div>
        </div>
    </div>
</div>
<div class="circulo-borde"></div>
<p-toast [baseZIndex]="11023"></p-toast>
<nb-layout windowMode>
    <nb-layout-header>
        <img *ngIf="showLogo" style=" display:block;margin:auto;" src="assets/images/logo.svg" alt="logo">
        <div *ngIf="navigateURL" class="container-back-link"><a class="back-link" (click)="goBackUrl()"><img
                    alt="icono regresar" src="assets/images/common/icon-back-2.svg">Volver</a></div>
    </nb-layout-header>
    <nb-layout-column></nb-layout-column>
    <nb-layout-column>
        <div *ngIf="containerTemplate" class="container">
            <ng-container *ngTemplateOutlet="containerTemplate"></ng-container>
        </div>
    </nb-layout-column>
    <nb-layout-column></nb-layout-column>
</nb-layout>