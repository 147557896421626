import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { isNumeric } from "rxjs/internal-compatibility";

@Pipe({
  name: "extractDataArrayPipe",
})
export class ExtractDataArrayPipe implements PipeTransform {
  constructor(private numberPipe: DecimalPipe) {}
  transform(values: any, args: any): any {
    if (!args) {
      return;
    }
    const stringTokenValue = "token";
    const tokenValue = args[stringTokenValue];
    const stringSubTokenValue = "subToken";
    const subTokenValue = args[stringSubTokenValue];
    const stringFilterPipe = "filter";
    const filterPipe = args[stringFilterPipe];
    const stringValueFilterPipe = "valueFilter";
    const valueFilterPipe = args[stringValueFilterPipe];
    const stringLength = "length";
    const lengthPipe = args[stringLength];
    const stringisNumeric = "isNumeric";
    const isNumber = args[stringisNumeric];
    const stringFormatNumeric = "formatNumeric";
    const formatNumeric = args[stringFormatNumeric];
    let returnValue = "";
    let returnValueNumeric: number = 0;
    let countRows = 0;

    if (tokenValue && values && Array.isArray(values)) {
      values.forEach((value: any) => {
        if (!lengthPipe || (lengthPipe && lengthPipe > countRows)) {
          if (!filterPipe || (filterPipe && value[filterPipe] === valueFilterPipe)) {
            if (isNumber && isNumeric(value[tokenValue])) {
              returnValueNumeric = +value[tokenValue];
            } else {
              if (value[tokenValue]) {
                returnValue += value[tokenValue];
              }

              if (subTokenValue && value[subTokenValue]) {
                returnValue += " " + value[subTokenValue];
              }

              returnValue += ",";
            }
            countRows++;
          }
        }
      });
      returnValue = returnValue.substring(0, returnValue.lastIndexOf(","));
    }
    if (isNumber) {
      returnValue = this.numberPipe.transform(returnValueNumeric, formatNumeric || "1.0-0");
    }
    return returnValue;
  }
}
