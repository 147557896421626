import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ("serviceWorker" in navigator && environment.swpwa) {
      navigator.serviceWorker
        .register("/fp-sw.js", {
          scope: "/",
        })
        .then((reg) => {
          reg.addEventListener("updatefound", () => {
            const newWorker = reg.installing;
            newWorker.addEventListener("statechange", () => {
              if (newWorker.state === "installed" && navigator.serviceWorker.controller) {
                newWorker.postMessage({ action: "skipWaiting" });
              }
            });
          });
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log("ServiceWorker registration failed: ", err));
      navigator.serviceWorker.addEventListener("controllerchange", (event) => {
        window.location.reload();
      });
    }
  })
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
