import { Component } from "@angular/core";
import { NbIconLibraries } from "@nebular/theme";
@Component({
  selector: "app-root",
  styleUrls: ["./app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "FacilPass BackOffice";

  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack("primeicons", { iconClassPrefix: "pi" });
  }
}
